import request from 'utils/request';

const api = {
  PERMISSION_LIST: '/api/permission/list',
  PERMISSION_ALL_LIST: '/api/offer/getAllAccount',
  PERMISSION_ALL_LIST_NEW: '/api/offer/getSourceManagers',
  PERMISSION_UPDATE: '/api/permission/updateAffSource',
  SELECT_GROUP_BUSINESS: '/api/permission/selectGroupBusiness',
  SELECT_GROUP_ACCOUNT: '/api/permission/selectGroupAccount',
  SELECT_ACCOUNT_ROLE: '/api/permission/getAccountByRole',
};
/**
 * 获取有权限的资源列表
 * @param {*} query {accountId:int,accountName:string,page:int,pageSize:int}
 */
export function getPermissionList(query) {
  return request({
    url: api.PERMISSION_LIST,
    method: 'get',
    params: query,
  });
}

/**
 * 获取有权限的资源所有列表
 * @param {*} query {accountId:int,accountName:string,page:int,pageSize:int}
 */
export function getPermissionAllList(query) {
  return request({
    url: api.PERMISSION_ALL_LIST,
    method: 'get',
    params: query,
  });
}

/**
 * 获取有权限的资源所有列表
 * @param {*} query {accountId:int,accountName:string,page:int,pageSize:int}
 */
export function getPermissionAllListNew(query) {
  return request({
    url: api.PERMISSION_ALL_LIST_NEW,
    method: 'get',
    params: query,
  });
}

/**
 * 修改用户资源
 * @param {*} data {accountId:int,sourceIds:Array<int>,affiliateIds:Array<int>}
 */
export function updatePermission(data) {
  return request({
    url: api.PERMISSION_UPDATE,
    method: 'put',
    data,
  });
}

export function selectGroupBusiness(query) {
  return request({
    url: api.SELECT_GROUP_BUSINESS,
    method: 'get',
    params: query,
  });
}

export function selectGroupAccount(query) {
  return request({
    url: api.SELECT_GROUP_ACCOUNT,
    method: 'get',
    params: query,
  });
}

export function selectAccountRole(query) {
  return request({
    url: api.SELECT_ACCOUNT_ROLE,
    method: 'get',
    params: query,
  });
}
