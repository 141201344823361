<template>
  <div class="pagination">
    <el-pagination
      :page-sizes="paginations.pageSizes"
      :page-size="pageSize"
      :layout="paginations.layout"
      :total="pageTotal"
      :current-page="currentPage"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    >
    </el-pagination>
  </div>
</template>

<script>
  export default {
    name: 'pagination',
    data() {
      return {
        paginations: {
          pageSizes: [10, 20, 50, 100, 600], //每页显示多少条
          layout: 'total, sizes, prev, pager, next, jumper', // 翻页属性
        },
      };
    },
    props: {
      pageTotal: {
        type: Number,
        default: 0,
      },
      currentPage: {
        type: Number,
        default: 1,
      },
      pageSize: {
        type: Number,
        default: 20,
      },
    },
    created() {},
    mounted() {},
    methods: {
      // 上下分页 currentPage
      handleCurrentChange(currentPage) {
        this.$emit('handleCurrentChange', currentPage);
      },
      // 每页多少条切换 pageSize
      handleSizeChange(size) {
        this.$emit('handleSizeChange', size);
      },
    },
  };
</script>

<style scoped></style>
