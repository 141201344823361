import { getPermissionList, updatePermission } from '@/api/account/permission';
import { getSourceList } from '@/api/source/index';
import { getAffiliateList } from '@/api/affiliate/index';
import Pagination from '@/components/pagination';
import { myMixin } from '@/mixins/mixins.js';
import { getPermissionAllList } from '@/api/account/permission';
export default {
  mixins: [myMixin],
  components: {
    Pagination,
  },
  data() {
    return {
      updateVisible: false,
      updateUser: {},
      filter: {
        accountName: null,
        accountId: null,
      },
      option: {
        manager: [],
      },
      affiliateTmpList: [],
      affiliateList: [],
      sourceTmpList: [],
      sourceList: [],
      total: 0,
      pageSize: 20,
      currentPage: 1,
      roleRightsList: [],
    };
  },
  mounted() {
    this.getaffiliateList();
    this.getsourceList();
    this.getUserAffSourceList();
    this.managerList();
  },
  methods: {
    getaffiliateList() {
      getAffiliateList().then((response) => {
        if (response.code === 200) {
          this.affiliateTmpList = response.result;
          this.affiliateTmpList.forEach((item, i) => {
            item = this.affiliateTmpList[i];
            this.affiliateList.push({
              value: item.id,
              label: `${item.id} - ${item.company}`,
            });
          });
        }
      });
    },
    getsourceList() {
      getSourceList().then((response) => {
        if (response.code === 200) {
          this.sourceTmpList = response.result;
          this.sourceTmpList.forEach((item, i) => {
            item = this.sourceTmpList[i];
            this.sourceList.push({
              value: item.id,
              label: `${item.id} - ${item.company}`,
            });
          });
        }
      });
    },
    getUserAffSourceList(page) {
      let param = {
        ...this.filter,
        page: page || this.currentPage,
        pageSize: this.pageSize,
      };
      getPermissionList(param).then((response) => {
        if (response.code === 200) {
          this.roleRightsList = response.result;
          this.total = response.total;
        }
      });
    },
    managerList() {
      getPermissionAllList().then((res) => {
        console.log(res);
        this.option.manager = res.result;
      });
    },
    filterAff(affiliates) {
      let html = '';
      if (Array.isArray(affiliates) && affiliates.length > 0) {
        this.affiliateTmpList.forEach((item) => {
          if (affiliates.includes(item.id)) {
            html += item.company + '(' + item.id + ')' + ' ';
          }
        });
      }
      return html;
    },
    filterSources(sources) {
      let html = '';
      if (Array.isArray(sources) && sources.length > 0) {
        this.sourceTmpList.forEach((item) => {
          if (sources.includes(item.id)) {
            html += item.company + '(' + item.id + ')' + ' ';
          }
        });
      }
      return html;
    },
    updateClick(row) {
      this.updateUser = {
        accountId: '',
        affiliateIds: [],
        sourceIds: [],
        subAccountIds: [],
      };
      this.updateUser.accountId = row.accountId;
      if (row.affIds != null && row.affIds.length > 0) {
        this.updateUser.affiliateIds = row.affIds;
      }
      if (row.srcIds != null && row.srcIds.length > 0) {
        this.updateUser.sourceIds = row.srcIds;
      }
      if (row.subIds != null && row.subIds.length > 0) {
        this.updateUser.subAccountIds = row.subIds;
      }
      this.updateVisible = true;
    },
    confirmUpdateClick() {
      updatePermission({ ...this.updateUser }).then((response) => {
        if (response.code === 200) {
          this.$message({
            message: 'Success',
            type: 'success',
          });
          this.getUserAffSourceList();
          this.updateVisible = false;
        } else {
          this.$message.error('Update Error' + response.message);
        }
      });
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.handleCurrentChange(1);
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.getUserAffSourceList(currentPage);
    },
  },
};
