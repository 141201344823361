<template>
  <div class="accountAff">
    <!-- <div class="main-Title bgff">
	  <h2>User-Aff Setting</h2>
	</div> -->
    <div class="mainBox">
      <el-card shadow="hover" class="mb10">
        <el-form :model="filter" label-width="100px" size="small">
          <el-row :gutter="10">
            <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
              <el-form-item label="Account ID" class="mb0" label-width="90px">
                <el-input v-model="filter.accountId" placeholder="account id here ..."></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
              <el-form-item label="Account Name" label-width="120px" class="mb0">
                <el-input
                  v-model="filter.accountName"
                  placeholder="account name regex here ..."
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
              <el-form-item label-width="0px" class="mb0">
                <el-button type="primary" @click="getUserAffSourceList(1)">In Browser</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <el-card shadow="hover">
        <el-table
          style="width:100%;"
          size="mini"
          stripe
          border
          :data="roleRightsList"
          highlight-current-row
        >
          <el-table-column label="Account ID" prop="accountId" align="center"></el-table-column>
          <el-table-column label="account" prop="username" align="center"></el-table-column>
          <el-table-column label="Open Source" prop="isOpenAllAdv" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.isOpenAllAdv == 1">Yes</span>
              <span v-else>No</span>
            </template>
          </el-table-column>
          <el-table-column label="Open Affiliate" prop="isOpenAllAdv" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.isOpenAllAff == 1">Yes</span>
              <span v-else>No</span>
            </template>
          </el-table-column>
          <el-table-column label="Source" align="center" show-overflow-tooltip>
            <template slot-scope="scope">
              <span v-if="scope.row.isOpenAllAdv == 1">ALL</span>
              <span v-else v-html="filterSources(scope.row.srcIds)"></span>
            </template>
          </el-table-column>
          <el-table-column label="Aff" align="center" show-overflow-tooltip>
            <template slot-scope="scope">
              <span v-if="scope.row.isOpenAllAff == 1">ALL</span>
              <span v-else v-html="filterAff(scope.row.affIds)"></span>
            </template>
          </el-table-column>
          <el-table-column label="Action" align="center">
            <template slot-scope="scope">
              <el-button type="primary" size="mini" @click="updateClick(scope.row)"
                >Update</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <pagination
          class="block pagePanel"
          :pageTotal="total"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          :pageSize="pageSize"
          :currentPage="currentPage"
        ></pagination>
        <el-dialog
          title="Update user"
          width="35%"
          :visible.sync="updateVisible"
          :close-on-click-modal="false"
        >
          <el-form :model="updateUser" label-width="150px" size="small">
            <el-form-item label="Choose Sub Account">
              <el-select
                v-model="updateUser.subAccountIds"
                clearable
                filterable
                multiple
                collapse-tags
                class="w100"
              >
                <el-option
                  v-for="item in option.manager"
                  :key="item.accountId"
                  :label="item.username"
                  :value="item.accountId"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Choose Affiliates">
              <el-select
                v-model="updateUser.affiliateIds"
                clearable
                filterable
                multiple
                collapse-tags
                class="w100"
              >
                <el-option
                  v-for="item in affiliateList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Choose Sources">
              <el-select
                v-model="updateUser.sourceIds"
                clearable
                filterable
                multiple
                collapse-tags
                class="w100"
              >
                <el-option
                  v-for="item in sourceList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label-width="0px" align="right" class="mb0">
              <el-button @click="updateVisible = false">取 消</el-button>
              <el-button type="primary" @click="confirmUpdateClick">确 定</el-button>
            </el-form-item>
          </el-form>
        </el-dialog>
      </el-card>
    </div>
  </div>
</template>
<script>
  import AccountAffCon from '../controllers/account/AccountAff';
  export default {
    name: 'AccountAff',
    ...AccountAffCon,
  };
</script>
<style lang="scss" scoped></style>
